<template>
  <div class="container">
    <!-- 收货地址栏 -->
    <div class="address-bar-selected" v-if="addressData.id" @click="handleGoAddress">
      <div>
        <div class='region-line'>
          <div class="default-address" v-if="addressData.isDefault">默认</div>
          <div class="region">{{addressData.regionStr}}</div>
        </div>
        <div class='address'>{{addressData.address}}</div>
        <div class='contact-info'>
          <span>{{addressData.username}}</span>
          <span class='phone-number'>{{addressData.phoneNumber}}</span>
        </div>
      </div>
      <a-icon type="right" />
    </div>
    <div class="address-bar" v-else @click="handleGoAddress">
      <div>请选择收货地址</div>
      <a-icon type="right" />
    </div>

    <!-- 石材详情栏 -->
    <div class='stone-bar' v-if="data.id">
      <img :src="data.image.url + '?x-oss-process=image/resize,w_100,m_lfit'" />
      <div>
        <div class='title'>{{data.title}}</div>
        <div class='spec-line'>
          <div class='price'>
            <div style="font-size: 18px;font-weight: normal;">¥</div>
            <div>{{data.price}}</div>
          </div>
          <a-input-number v-model="count" :min="0" :max="data.totalCount" @change="handleChangeCount" />
        </div>
      </div>
    </div>

    <!-- 规格选择 -->
    <div class="spec-bar">
      <div>选择样品规格</div>
      <a-radio-group v-model="spec" @change="handleChangeSpec">
        <a-radio value="FifteenMulSeven">15*7</a-radio>
        <a-radio value="FifteenMulFifteen">15*15</a-radio>
      </a-radio-group>
    </div>

    <!-- 代币抵扣 -->
    <div class='price-bar'>
      <div>
        <div>商品总价</div>
        <div class="price">¥{{totalPrice}}</div>
      </div>
      <div>
        <div>使用代币</div>
        <a-input-number v-model="usedToken" :min="0" :max="Math.min(totalPrice/ratio, token)" @change="handleChangeToken" />
      </div>
      <div>
        <div>合计：</div>
        <div class="price">¥{{paidPrice > 0 ? paidPrice : 0}}</div>
      </div>
    </div>

    <a-button class="order-bar-btn" type="danger" @click="handleAddOrder">下单</a-button>
  </div>
</template>

<script>
export default {
  name: 'AddOrder',

  data () {
    return {
      // 地址信息
      addressData: {},
      // 石材数据
      data: {},
      // 石材样品数量
      count: 1,
      // 目前拥有的代币数
      token: 0,
      // 1元:1token
      ratio: 1,
      // 使用多少代币进行抵扣
      usedToken: 0,
      // 总价
      totalPrice: 0,
      // 实际需要支付的价格
      paidPrice: 0,
      // 石材规格
      spec: 'FifteenMulSeven'
    }
  },

  mounted () {
    if (!this.$store.state.stoneID) return this.$router.replace('/')
    if (this.$store.state.addressData) {
      this.addressData = this.$store.state.addressData
    } else {
      this.getDefaultAddress()
    }
    this.getStone()
    this.getToken()
  },

  methods: {
    // 获取数据
    getStone () {
      this.api.getStone(this.$store.state.stoneID).then(res => {
        this.data = res.data.getStone
        this.handleChangeCount()
      })
    },

    // 获取代币
    getToken () {
      this.api.getUserInfo().then(res => {
        this.token = res.data.getInfoByUser.token
      })
    },

    // 获取默认收货地址
    getDefaultAddress () {
      this.api.getDefaultAddress().then(res => {
        const address = res.data.getDefaultAddressByUser || {}
        if (address.region && Array.isArray(address.region) && address.region.length === 3) {
          if (['北京市', '上海市', '天津市', '重庆市'].includes(address.region[0])) {
            address.regionStr = address.region[1] + address.region[2]
          } else {
            address.regionStr = address.region.join('')
          }
        }
        this.addressData = address
      })
    },

    // 修改石材样品数量
    handleChangeCount () {
      // 计算总价和使用代币后的实际支付价格
      const totalPrice = (this.count * this.data.price).toFixed(2)
      let usedToken = this.usedToken
      let paidPrice = (totalPrice - usedToken * this.ratio).toFixed(2)
      if (totalPrice < usedToken * this.ratio) {
        paidPrice = 0
        usedToken = totalPrice / this.ratio
      }
      this.totalPrice = totalPrice
      this.paidPrice = paidPrice
      this.usedToken = usedToken
    },
    // 修改使用代币数
    handleChangeToken () {
      const ratio = this.ratio
      // 计算总价
      const totalPrice = (this.count * this.data.price).toFixed(2)
      // 计算使用代币后的实际支付价格
      this.paidPrice = (totalPrice - (this.usedToken * ratio).toFixed(2)).toFixed(2)
      this.totalPrice = totalPrice
    },

    // 选择规格
    handleChangeSpec (e) {
      this.spec = e.target.value
    },

    // 跳转收货地址列表
    handleGoAddress () {
      if (this.$store.state.currentPath === '/address-list') return
      this.$router.push('/address-list')
    },

    // 下单
    handleAddOrder () {
      if (this.count <= 0 || this.count > this.data.totalCount) {
        return this.$message.warn('样品数有误')
      }
      const args = {
        addressID: this.addressData.id,
        usedToken: this.usedToken,
        remark: '',
        stoneList: [{
          count: this.count,
          spec: this.spec,
          stoneID: this.data.id
        }]
      }
      this.api.addOrderByUser(args).then(res => {
        if (res.data.addOrderByUser.id) {
          this.$message.success('添加成功，请扫码支付')
          this.$router.push(`/order/${res.data.addOrderByUser.id}`)
        }
      })
    }
  }
}
</script>

<style scoped>
.container {
  min-width: 600px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  margin-top: 20px;
}

.address-bar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  cursor: pointer;
}

.address-bar-selected {
  display: grid;
  grid-template-columns: 1fr 50px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 25px;
  cursor: pointer;
}
.address-bar-selected > div {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
}
.region-line {
  display: flex;
  align-items: center;
}
.default-address {
  font-size: 12px;
  color: #fff;
  background-color: var(--red-color);
  margin-right: 10px;
  padding: 0 6px;
  border-radius: 5px;
}
.region {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.address {
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phone-number {
  margin-left: 15px;
}

.stone-bar {
  display: grid;
  grid-template-columns: 100px auto;
  column-gap: 30px;
  align-items: center;
  background-color: #fff;
  padding: 20px;
}
.stone-bar > img {
  object-fit: cover;
  width: 100px;
  height: 100px;
}
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
}
.spec-line {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.price {
  color: var(--red-color);
  font-size: 22px;
  font-weight: bolder;
  display: flex;
  align-items: center;
}

.spec-bar {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  background-color: #fff;
  padding: 20px;
}

.price-bar {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  background-color: #fff;
  padding: 20px;
}
.price-bar > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price-bar > div:last-child {
  justify-content: flex-end;
  border-top: 1px solid #f5f5f5;
  padding-top: 10px;
}
.price-bar .price {
  font-size: 18px;
}

.address-bar:hover, .address-bar-selected:hover, .stone-bar:hover, .price-bar:hover {
  box-shadow: 0 0 5px 1px rgba(111, 111, 111, 0.1);
}

.order-bar-btn {
  height: 60px;
  font-size: 20px;
  font-weight: bold;
}
</style>
